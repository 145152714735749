module.exports = [
    {
        city_code: "1",
        city: "北京",
        state: "1",
        id: "4",
    },
    {
        city_code: "2",
        city: "天津",
        state: "1",
        id: "5",
    },
    {
        city_code: "3",
        city: "河北",
        state: "1",
        id: "6",
    },
    {
        city_code: "4",
        city: "山西",
        state: "1",
        id: "7",
    },
    {
        city_code: "6",
        city: "辽宁",
        state: "1",
        id: "9",
    },
    {
        city_code: "7",
        city: "吉林",
        state: "1",
        id: "10",
    },
    {
        city_code: "8",
        city: "黑龙江",
        state: "1",
        id: "11",
    },
    {
        city_code: "9",
        city: "上海",
        state: "1",
        id: "12",
    },
    {
        city_code: "10",
        city: "江苏",
        state: "1",
        id: "13",
    },
    {
        city_code: "11",
        city: "浙江",
        state: "1",
        id: "14",
    },
    {
        city_code: "12",
        city: "安徽",
        state: "1",
        id: "15",
    },
    {
        city_code: "13",
        city: "福建",
        state: "1",
        id: "16",
    },
    {
        city_code: "14",
        city: "江西",
        state: "1",
        id: "17",
    },
    {
        city_code: "15",
        city: "山东",
        state: "1",
        id: "18",
    },
    {
        city_code: "16",
        city: "河南",
        state: "1",
        id: "19",
    },
    {
        city_code: "17",
        city: "湖北",
        state: "1",
        id: "20",
    },
    {
        city_code: "18",
        city: "湖南",
        state: "1",
        id: "21",
    },
    {
        city_code: "19",
        city: "广东",
        state: "1",
        id: "22",
    },
    {
        city_code: "21",
        city: "海南",
        state: "1",
        id: "24",
    },
    {
        city_code: "22",
        city: "重庆",
        state: "1",
        id: "25",
    },
    {
        city_code: "23",
        city: "四川",
        state: "1",
        id: "26",
    },
    {
        city_code: "24",
        city: "贵州",
        state: "1",
        id: "27",
    },
    {
        city_code: "25",
        city: "云南",
        state: "1",
        id: "28",
    },
    {
        city_code: "27",
        city: "陕西",
        state: "1",
        id: "30",
    },
    {
        city_code: "28",
        city: "甘肃",
        state: "1",
        id: "31",
    },
    {
        city_code: "29",
        city: "青海",
        state: "1",
        id: "32",
    },
    {
        city_code: "30",
        city: "宁夏",
        state: "1",
        id: "33",
    },
    {
        city_code: "5",
        city: "内蒙古",
        state: "1",
        id: "35",
    },
    {
        city_code: "20",
        city: "广西",
        state: "1",
        id: "37",
    },
    {
        city_code: "31",
        city: "新疆",
        state: "1",
        id: "38",
    },
    {
        city_code: "26",
        city: "西藏",
        state: "1",
        id: "39",
    },
]